<template>
  <div class="license_container">
    <el-container>
      <el-main>
        <div class="main_box">
          <!-- 头部 -->
          <PageHeader></PageHeader>
        </div>
        <!-- 中间文字部分 -->
        <div class="text_main">
          <div class="text_box">
            <div class="title">乐动科技</div>
            <div style="text-align:left;line-height: 26px;">
              东莞市乐动文化科技有限公司（简称乐动科技）成立于2019年，注册资金10万。公司地址于大岭山元新路55号，是一家以驾培行业为主要方向的软件开发企业，专注于驾考管理和学员培训学习等场景实现数字化，致力于为客户提供易用、专业、优质、低价的技术解决方案和服务，
              公司注重开放、专注、创新、求是，发挥技术人的优势，重用和培养行业较高水平的技术人才，创造更高的价值.
            </div>
            <div style=" font-weight: bold;">公司价值观：学员不需要被教育，只需要被提醒.</div>
          </div>

        </div>
      </el-main>

      <!-- 底部 -->
      <el-footer>
        <BottomFooter></BottomFooter>
      </el-footer>
    </el-container>

  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  import PageHeader from '../components/PageHeader'

  export default {
    name: 'WorkspaceJsonLicense',
    components: {
      BottomFooter,
      PageHeader
    },

    data() {
      return {
        license_pic: require('../assets/license.jpg')

      };
    },

    mounted() {

    },

    methods: {

    },
  };

</script>

<style lang="scss" scoped>
  .license_container {
    // background: url(".././assets/bgc.jpg") no-repeat center center / auto 100% rgb(245, 245, 245);
    width: 100%;
    min-width: 960px;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;

    .el-container {
      height: 100%;

      .el-main {
        padding: 0 !important;

        .main_box {
          width: 100%;
          height: calc(100vh - 300px);
          background: url(".././assets/bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0
        }

        .text_main {
          width: 100%;
          height: 120px;
          box-sizing: border-box;
          padding: 10px;

          .text_box {
            width: 100%;
            height: 160px;
            border: 3px solid #ccc;
            border-radius: 14px;
            box-sizing: border-box;
            padding: 10px;

            .title {
              font-weight: bold;
              font-size: 17px;
              margin-bottom: 10px;

            }
          }
        }
      }

      .el-footer {
        height: 100px !important;
        background-color: #fff;

        .index_footer {
          width: 90%;
          height: 100%;
          margin: 0px auto;
          display: flex;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left_box {
            width: 350px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .left_item {
              width: 90px;
              cursor: pointer;
              margin-bottom: 6px;
            }

          }
        }
      }
    }
  }

</style>
